import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Alert, Label } from 'design-react-kit'
import { getDefaultAxiosConfig, getFormazioneResourceApi } from 'src/app/utils/ManagerRestGateway'
import GenericBreadCrumb from 'src/frontend/app/components/GenericBreadCrumb'
import GenericTab from 'src/frontend/app/components/GenericTab'
import { createFormData, getTabActiveFormId, handleChangeFormData, validateFormData } from 'src/app/utils/form-utils'
import formValidationRules from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneEvento/FormRules'
import { convertBooleans } from 'src/utilities/utility'
import { STATI_EVENTO_CATALOGO_VALUES } from 'src/formazione'

// Form components
import FormDatiEvento from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneEvento/FormDatiEvento'
import FormDatiAttivita from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneEvento/FormDatiAttivita'
import FormDatiInterventi from 'src/frontend/app/pages/iscrizioneAlboEF/formCreazioneEvento/FormDatiInterventi'
import FormSubmit, { STATUS } from 'src/frontend/app/components/Form/GenericSubmit'
import { PageSection, Title } from '@patternfly/react-core'

import moment from 'moment'
import { TokenStore } from 'src/keycloak/jwt/TokenStore'
import GenericTableCompact from '../../components/GenericTableCompact'
import GenericModal from '../../components/GenericModal'
import GenericButton from '../../components/GenericButton'
import { getOrdineCompetenteOperatorePOST } from 'src/processes/Ordini'
import { generateRequestFilter } from 'src/app/utils/ApiUtils'
import { getDatiOrdineFederazioneByCodiceGruppo } from 'src/processes/Soggetto'
import { getAgenziaFormativa } from 'src/hooks/useReqOptions'

const title = 'Creazione Evento a Catalogo'
const listNavLink = [
  { id: 1, label: 'Dati Evento' },
  { id: 2, label: 'Dati Attività' },
  { id: 3, label: 'Dati Interventi' },
  { id: 4, label: 'Conferma' },
]

const breadcrumbOptions = [
  { label: '', link: '/' },
  { label: '', link: '/' },
]

const CreazioneEvento: React.FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const tab = searchParams.get('tab')
  const [recapCfpPofResponse, setRecapCfpPofResponse] = useState<any>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [annoInizio, setAnnoInizio] = useState<string>(null)
  const [isCONAF, setIsCONAF] = useState<boolean>(false)

  // Tab component state
  const [activeTab, setActiveTab] = useState(parseInt(tab || '1'))
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>(null)

  // Form State
  const refForm = useRef<any>([])
  const [data, setData] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const formData = createFormData(data, setData, errors, setErrors)

  // Submit state
  const [reqStatus, setReqStatus] = useState<any>()
  const [reqMessage, setReqMessage] = useState<any>()

  // update tab in search params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', `${activeTab}`)
    history.replace({ search: searchParams.toString() })
    setIsCONAF(TokenStore.getInstance().isCurrentAccountCONAF())
  }, [activeTab])

  // retrieve data from api
  useEffect(() => {
    const retrieveData = async () => {}
    // before the api retrieve the data from local storage
    const dataString = localStorage.getItem(`${id}`)
    const _data = dataString ? JSON.parse(dataString) : {}
    setData(_data)
    //if (id) retrieveData().catch(console.error)
  }, [id])

  // save data in local storage
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem(`${id}`, JSON.stringify(data))
    }, 200)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [id, data])

  const onInitInput = (tab) => (cmp) => {
    refForm.current.push({
      tab: tab,
      cmp: cmp,
    })
  }

  const handleInput = (code, value) => {
    handleChangeFormData(formData, code, value, formValidationRules)
  }

  const onNextStep = useCallback(
    (e) => {
      e.preventDefault()
      const inputs = getTabActiveFormId(refForm, activeTab - 1)
      const isValid = validateFormData(formData, formValidationRules, inputs)
      if (!isValid) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        return
      }

      const checkIfPofExists = async (): Promise<boolean> => {
        const {
          data: { items },
        } = await getFormazioneResourceApi().msfoFormazioneListaPformativiForUserGet(
          generateRequestFilter([{ field: 'anno', value: moment(data.dataInizio).format('YYYY') }]),
          100,
          -1,
          undefined,
          getDefaultAxiosConfig()
        )

        if (items.length === 0) {
          return false
        }

        return true
      }

      const retrieveData = async (annoInizioPOF: string, specificGroup: string) => {
        let ordine: string[] = []

        if (specificGroup.startsWith('ODAF')) {
          ordine = (await getOrdineCompetenteOperatorePOST(specificGroup)).data
        } else if (specificGroup.startsWith('FODAF')) {
          const mappaCodiceConaf = (await getDatiOrdineFederazioneByCodiceGruppo(specificGroup)).data.returnedObject[0].mappaCodiceConaf
          ordine.push(mappaCodiceConaf)
        } else if (specificGroup.startsWith('FO_')) {
          const age = (await getAgenziaFormativa()).id
          ordine.push(age)
        }

        const response = await getFormazioneResourceApi().msfoFormazioneRecapCfpPofGet(annoInizioPOF, ordine, getDefaultAxiosConfig())
        setRecapCfpPofResponse(response.data)
      }

      if (!isCONAF) {
        if (tab === '1') {
          checkIfPofExists().then((pofExists) => {
            if (!pofExists) {
              setErrorMessage('POF non esistente per la data di inizio selezionata')
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              return
            }
            // da rimuovere quando verrà approvata la MEV che obbliga l'inserimento delle date per i FAD asincroni
            let annoInizio: string
            if (formData.data.modalita.value === 'FAD_ASINCRONO') {
              annoInizio = '2024'
            } else {
              annoInizio = formData.data.dataInizio.split('-')[0]
            }
            setAnnoInizio(annoInizio)

            const specificGroup =
              TokenStore.getInstance().getCurrentAccount().accounts[Object.keys(TokenStore.getInstance().getCurrentAccount().accounts)[0]].specificGroup

            retrieveData(annoInizio, specificGroup).catch(console.error)
            setMessage('')
            setErrorMessage(null)
            setActiveTab((tab) => tab + 1)
          })
        } else if (tab === '2') {
          let cfpCount: number
          const selectedSettore = recapCfpPofResponse?.find((row) => row.settore === data.settore.id.toString())
          if (selectedSettore) {
            if (formData.data.totOreCFU) {
              const [hours, minutes] = formData.data.totOreCFU.split(':').map(Number)
              const totalHours = hours + minutes / 60
              cfpCount = parseFloat((totalHours / 8).toFixed(3)) // 1 CFP for every 8 hours
            } else if (formData.data.crediti) {
              cfpCount = parseFloat(formData.data.crediti.toFixed(3))
            }

            if (cfpCount > selectedSettore.cfpRimanenti.toFixed(3)) {
              setErrorMessage(
                `I CFP inseriti per l'evento che si sta creando (${cfpCount}) superano i CFP rimanenti per lo SDAF selezionato (${
                  selectedSettore.cfpRimanenti.toFixed(3) < 0 ? 0 : selectedSettore.cfpRimanenti.toFixed(3)
                }).`
              )
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              return
            }
          }

          setMessage('')
          setErrorMessage(null)
          setActiveTab((tab) => tab + 1)
        } else {
          setMessage('')
          setActiveTab((tab) => tab + 1)
        }
      } else {
        setMessage('')
        setActiveTab((tab) => tab + 1)
      }
    },
    [activeTab, formData]
  )

  const previousStep = () => {
    if (reqStatus === STATUS.LOADING) {
      return
    }
    setReqStatus(null)
    setReqMessage(null)
    setErrorMessage(null)
    setActiveTab((tab) => tab - 1)
  }

  const onSubmit = useCallback(async () => {
    try {
      setReqStatus(STATUS.LOADING)

      const { data } = formData || {}
      const _data = convertBooleans(data)
      _data.accreditato = STATI_EVENTO_CATALOGO_VALUES.RICHIESTA
      _data.maxPRemoto = _data.maxPRemoto || 1
      _data.maxPSitu = _data.maxPSitu || 1

      let max = null
      if (_data.evento && _data.dataInizio && _data.evento.dataFine) {
        const diffInDays = moment(_data.evento.data_fine).diff(moment(_data.evento.data_inizio), 'days')
        max = Math.floor(diffInDays)
      }

      if (!_data.tipologiaAttivita) {
        _data.crediti = 0
      } else {
        if (_data.tipologiaAttivita.um === 'H') {
          const totOreCFU = _data.totOreCFU
          if (totOreCFU && totOreCFU.indexOf(':') > 0) {
            const ore = parseInt(totOreCFU.split(':')[0])
            const minuti = parseInt(totOreCFU.split(':')[1])
            _data.crediti = ((ore + minuti / 60) / _data.tipologiaAttivita.ore_evt_battute) * _data.tipologiaAttivita.max_cfp_evt
          } else {
            _data.crediti = _data.tipologiaAttivita.max_cfp_evt
          }

          if (max) {
            _data.crediti = _data.crediti > max ? max : _data.crediti
          }
        } else {
          if (_data.tipologiaAttivita.cod === 'AO' || _data.tipologiaAttivita.cod === 'AF') {
            _data.crediti = 0.25
          }
        }
      }

      _data.crediti = Math.round(_data.crediti * 1000) / 1000

      if (!_data.areaProfessionale2 || _data.areaProfessionale2.length === 0) {
        _data.areaProfessionale2 = []
      }
      if (TokenStore.getInstance().isCurrentAccountCONAF()) {
        const nome = TokenStore.getInstance().getSpidData().nome
        const cognome = TokenStore.getInstance().getSpidData().cognome
        _data.accreditatoDa = nome.substring(0, 1) + '.' + cognome
      }

      await getFormazioneResourceApi().msfoFormazioneInserisciEventoACatalogoPost(_data, getDefaultAxiosConfig())
      setReqStatus(STATUS.SUCCESS)
      setReqMessage('Richiesta inviata con successo')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } catch (e) {
      setReqStatus(STATUS.FAILURE)
      setReqMessage("Errore durante l'invio della richiesta")
    }
    return null
  }, [formData])

  return (
    <div className="container register-container">
      <GenericBreadCrumb paths={breadcrumbOptions}></GenericBreadCrumb>
      <div className="row">
        <div className="col">
          <h1>{title}</h1>
        </div>
        {tab === '2' && !isCONAF && (
          <div className="col-2 d-flex flex-column px-4">
            <GenericButton
              className="mt-auto"
              color={'primary'}
              label={'Visualizza il saldo dei CFP per il POF corrente'}
              onClickEvent={() => setIsModalOpen(true)}
            />
          </div>
        )}
      </div>
      {errorMessage && (
        <Alert color="danger" className="mt-4">
          {errorMessage}
        </Alert>
      )}
      <GenericTab
        hiddenAlert={message === ''}
        labelAlert={message}
        isSubmitted={false}
        onSubmit={onNextStep}
        previousStep={previousStep}
        activeTab={activeTab}
        listNavLink={listNavLink}
        listBodyTab={[
          {
            id: 1,
            body: <FormDatiEvento title="Dati Evento" data={data} errors={errors} onInitInput={onInitInput(0)} handleInput={handleInput} />,
          },
          {
            id: 2,
            body: (
              <FormDatiAttivita
                title="Dati Attivita"
                data={data}
                listaSDAFPof={recapCfpPofResponse}
                errors={errors}
                onInitInput={onInitInput(1)}
                handleInput={handleInput}
              />
            ),
          },
          {
            id: 3,
            body: <FormDatiInterventi title="Dati Interventi" data={data} errors={errors} onInitInput={onInitInput(2)} handleInput={handleInput} />,
          },
          {
            id: 4,
            body: (
              <FormSubmit title="Richiesta evento" buttonLabel="Invia Richiesta" alertMessage={reqMessage} status={reqStatus} onSubmit={onSubmit}>
                <Label>Per confermare e inviare la domanda cliccare "Invia Richiesta".</Label>
              </FormSubmit>
            ),
          },
        ]}
      />
      <PageSection>
        <div className="container register-container">
          <Title headingLevel="h3">Legenda</Title>
          <p>* Campo obbligatorio</p>
          <p>** Campo non modificabile compilato automaticamente</p>
        </div>
      </PageSection>
      <GenericModal
        title={`Saldo CFP - POF ${annoInizio}`}
        text={
          <GenericTableCompact
            style={{ border: '1px solid grey' }}
            columns={['SDAF', 'CFP POF', 'CFP RIMANENTI']}
            rows={recapCfpPofResponse?.map((row) => (
              <tr>
                <td>{`SDAF${row?.settore?.length === 1 ? '0' : ''}${row?.settore}`}</td>
                <td>{row?.cfpPof?.toFixed(3)}</td>
                <td>{row?.cfpRimanenti?.toFixed(3) < 0 ? 0 : row?.cfpRimanenti?.toFixed(3)}</td>
              </tr>
            ))}
          />
        }
        centered={true}
        modalOpen={isModalOpen}
        closeButton={() => setIsModalOpen(false)}
      />
    </div>
  )
}

export default CreazioneEvento
